// this creates a sublist based on the selected tag

import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage } from "gatsby-plugin-image"

import { kebabCase } from 'lodash'
import {MainList,CatList} from '../components/utils.js'
import {Definition} from '../components/definitions.js'

const Technology = ({ pageContext, data }) => {
  const { technology } = pageContext;
  const { edges, totalCount } = data.technology;
  const totalNow = data.projects.totalCount;
  const totalTalk= data.talks.totalCount;
  return (
    <Layout>
      <div className={"container"}>
        <MainList project={true} total={totalNow} totalTalk={totalTalk}/>
        <CatList technology={true}/>
        <ul className={"tagList"}>
			<Link to={`/technology/${kebabCase(technology)}/`}>
				<li key={technology} className={"active"}>
					{technology} ({totalCount})
				</li>
			</Link>
		</ul>
     <Definition type={"technology"} lookup={technology}/>
        {edges.map(({ node }) => (
          <section key={node.id}>
          <Link to={node.fields.slug}>
            <h3>
              {node.frontmatter.title}{", "}
              <span>
                {node.frontmatter.date}
              </span>
            </h3>
            <div className={"row"}>
                <div className={"col col40"}>
                	<GatsbyImage className="rnd" image={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="image of technology"/>
                </div>
                <div className={"col col60"}>
                   <div className={"row"}>
                    <div className={"lft pd"}>
                        <p>{node.excerpt}</p>
                    </div> 
                   </div>
                </div>
            </div>
            </Link>
          </section>
          ))}
           <MainList project={true} total={totalNow} totalTalk={totalTalk}/>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($technology: String) {
  	projects: allMarkdownRemark(
      limit: 2000
      filter: {frontmatter: {template: {eq: "project"}}}
    ) {
      totalCount
    }
    talks: allMarkdownRemark(
      limit: 2000
      filter: {frontmatter: {template: {eq: "talk"}}}
    ) {
      totalCount
    }
    technology: allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { technology: { in: [$technology] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt(pruneLength: 300)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
               childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
               }
            }
          }
        }
      }
    }
  }
`;

export default Technology;